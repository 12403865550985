import classNames from "classnames";
import * as React from "react";
import { TeamList } from "@components/TeamList";
import { useFavoriteTargatTeamTabs } from "@hooks/useFavoriteTargatTeamTabs";
import styles from "./index.module.css";

export const TeamSection: React.FC = () => {
  const {
    selectedTabIndex,
    currentTournament,
    teamsByCurrentTournament,
    categoryTabs,
    changeTournament,
  } = useFavoriteTargatTeamTabs();

  return (
    <section className={classNames(styles.section, styles.team)}>
      <div className={classNames("l-main__innerWidth", styles.container)}>
        <div className={styles.heading}>
          <h2 className="c-contentTitle c-contentTitle--fz24 md:none">
            チームから探す
          </h2>
          <h2 className="c-contentTitle c-contentTitle--fz24 none md:block">
            チームからスポーツバーを探す
          </h2>
        </div>
      </div>

      <div className={styles.categoryTab}>
        {categoryTabs.map((tab, i) => {
          const label = tab.label;
          return (
            <button
              key={i}
              className={classNames(styles.categoryTabItem, {
                [styles.isActive]: selectedTabIndex === i,
              })}
              onClick={() => {
                changeTournament(i, tab.previouslySelectedTournamentId);
              }}
            >
              <span className={styles.name}>{label}</span>
            </button>
          );
        })}
      </div>

      <div className={classNames("l-main__innerWidth", styles.container)}>
        {categoryTabs.map((tab, i) => {
          return (
            <div
              key={i}
              className={classNames(styles.categoryInner, {
                [styles.isActive]: selectedTabIndex === i,
              })}
            >
              <div className={styles.teamFilter}>
                {tab.tournamentTabs.length > 1 &&
                  tab.tournamentTabs.map((tournament) => (
                    <button
                      key={tournament.id}
                      className={classNames(styles.teamFilterItem, {
                        [styles.isActive]:
                          currentTournament?.id === tournament.id,
                      })}
                      onClick={(): void => {
                        changeTournament(i, tournament.id);
                      }}
                    >
                      <span className={styles.tournament}>
                        {tournament?.displayName}
                      </span>
                    </button>
                  ))}
              </div>

              {currentTournament?.shouldShowDazn && (
                <div className={styles.heading}>
                  <div className={styles.logoDazn}>Powered by DAZN</div>
                </div>
              )}

              <div
                className={classNames(styles.teamListWrapper, {
                  [styles.hasList]: teamsByCurrentTournament.length > 0,
                })}
              >
                <TeamList teams={teamsByCurrentTournament} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
