import Link from "next/link";
import * as React from "react";
import { Text } from "@components/Text";
import styles from "./index.module.css";

const PopularAreaList: React.FC = () => {
  return (
    <div className={styles.popularArea}>
      <Text tag="h3" size={15} bold>
        人気エリア
      </Text>

      <div className="md:none">
        <ul className={styles.popularAreaList}>
          <li>
            <Link href="/shops/tokyo/ca-1/shibuya">渋谷</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-1/ebisu">恵比寿</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-2/shinjuku">新宿</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-3/ikebukuro">池袋</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-4/akihabara">秋葉原</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-7/ginza">銀座</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-34/shimbashi">新橋</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-9/ueno">上野</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-10/kichijoji">吉祥寺</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-11/machida">町田</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-20/yokohama">横浜駅周辺</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-21/kawasaki">川崎駅周辺</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-21/musashikosugi">武蔵小杉</Link>
          </li>
          <li>
            <Link href="/shops/chiba/ca-16/chiba">千葉</Link>
          </li>
          <li>
            <Link href="/shops/chiba/ca-17/funabashi">船橋</Link>
          </li>
          <li>
            <Link href="/shops/saitama/ca-15/omiya">大宮</Link>
          </li>
          <li>
            <Link href="/shops/saitama/ca-15/urawa">浦和</Link>
          </li>
          <li>
            <Link href="/shops/aichi/ca-24/sakae">栄</Link>
          </li>
          <li>
            <Link href="/shops/osaka/ca-39/umeda">梅田</Link>
          </li>
          <li>
            <Link href="/shops/hyogo/ca-27/sannomiya">三宮</Link>
          </li>
          <li>
            <Link href="/shops/miyagi/ca-12/sendai">仙台</Link>
          </li>
        </ul>
      </div>

      <div className="none md:block">
        <ul className={styles.popularAreaList}>
          <li>
            <Link href="/shops/tokyo/ca-1/shibuya">渋谷</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-1/ebisu">恵比寿</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-2/shinjuku">新宿</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-3/ikebukuro">池袋</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-3/takadanobaba">高田馬場</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-4/akasaka">赤坂</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-4/akihabara">秋葉原</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-6/suidobashi">水道橋</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-6/kanda">神田</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-7/ginza">銀座</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-7/shinagawa">品川</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-8/kamata">蒲田</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-34/shimbashi">新橋</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-34/hamamatsucho">浜松町</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-9/ueno">上野</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-9/kinshicho">錦糸町</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-10/kichijoji">吉祥寺</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-11/machida">町田</Link>
          </li>
          <li>
            <Link href="/shops/tokyo/ca-11/tachikawa">立川</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-20/yokohama">横浜駅周辺</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-20/kannai">関内</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-21/kawasaki">川崎駅周辺</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-21/musashikosugi">武蔵小杉</Link>
          </li>
          <li>
            <Link href="/shops/kanagawa/ca-22/fujisawa">藤沢</Link>
          </li>
          <li>
            <Link href="/shops/chiba/ca-16/chiba">千葉</Link>
          </li>
          <li>
            <Link href="/shops/chiba/ca-17/funabashi">船橋</Link>
          </li>
          <li>
            <Link href="/shops/chiba/ca-18/kashiwa">柏</Link>
          </li>
          <li>
            <Link href="/shops/saitama/ca-15/omiya">大宮</Link>
          </li>
          <li>
            <Link href="/shops/saitama/ca-15/urawa">浦和</Link>
          </li>
          <li>
            <Link href="/shops/aichi/ca-24/nagoya">名古屋駅周辺</Link>
          </li>
          <li>
            <Link href="/shops/aichi/ca-24/sakae">栄</Link>
          </li>
          <li>
            <Link href="/shops/osaka/ca-39/umeda">梅田</Link>
          </li>
          <li>
            <Link href="/shops/osaka/ca-40/namba">なんば</Link>
          </li>
          <li>
            <Link href="/shops/osaka/ca-40/shinsaibashi">心斎橋</Link>
          </li>
          <li>
            <Link href="/shops/hyogo/ca-27/sannomiya">三宮</Link>
          </li>
          <li>
            <Link href="/shops/miyagi/ca-12/sendai">仙台</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PopularAreaList;
