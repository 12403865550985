import * as React from "react";
import { NewsCard } from "@components/NewsCard";
import type { NewsCard_NewsArticleListItemFragment } from "@components/NewsCard/__generated__/fragments";
import { TopListBox } from "@components/TopListBox";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import styles from "./index.module.css";

type Props = {
  newsArticle: NewsCard_NewsArticleListItemFragment;
  favoriteTeamIds: number[];
};

export const FavoriteTeamNewsBox: React.FC<Props> = ({
  newsArticle,
  favoriteTeamIds,
}) => {
  return (
    <div className={styles.container}>
      <TopListBox title="ニュース">
        <NewsCard
          newsArticle={newsArticle}
          onClick={() => {
            GTMEvent(
              GTMEvents.toNewsDetailPagePressed(
                "favorite_team_news_box",
                newsArticle.id,
                favoriteTeamIds
              )
            );
          }}
        />
      </TopListBox>
    </div>
  );
};
