import ReactDOM from "react-dom";
import type React from "react";
import type { FC } from "react";

type Props = {
  children: React.ReactNode;
  targetId: string;
};

const Portal: FC<Props> = ({ children, targetId }) => {
  if (typeof document === "undefined") {
    return null;
  }
  const container = document.getElementById(targetId);
  return container ? ReactDOM.createPortal(children, container) : null;
};

export default Portal;
