import _ from "lodash";
import Link from "next/link";
import { useEffect, useState } from "react";
import type {
  UsersCombinedAreaType,
  UsersMasterPrefectureType,
} from "@graphql/__generated__/types";
import { IFImg } from "@libs/utils/IFImg";
import { checkIsPcByScreenWidth } from "@libs/utils/browser";
import styles from "./index.module.css";
import type { FC } from "react";

type CombinedAreaType = Pick<UsersCombinedAreaType, "id" | "name" | "slug"> & {
  masterPrefecture?: Pick<UsersMasterPrefectureType, "slug">;
  alt?: string;
};

// TOPに出すエリア一覧
// TOP表示としてはCombinedArea.nameではなく代表エリア名を使用
const FEATURED_AREAS: CombinedAreaType[] = [
  {
    id: 1,
    name: "東京",
    masterPrefecture: {
      slug: "tokyo",
    },
    alt: "東京都内でスポーツ観戦ができるスポーツバー",
  },
  {
    id: 2,
    name: "神奈川",
    masterPrefecture: {
      slug: "kanagawa",
    },
    alt: "神奈川県内でスポーツ観戦ができるスポーツバー",
  },
  {
    id: 3,
    name: "埼玉",
    masterPrefecture: {
      slug: "saitama",
    },
    alt: "埼玉県内でスポーツ観戦ができるスポーツバー",
  },
  {
    id: 4,
    name: "愛知",
    masterPrefecture: {
      slug: "aichi",
    },
    alt: "愛知県内でスポーツ観戦ができるスポーツバー",
  },
  {
    id: 5,
    name: "大阪",
    masterPrefecture: {
      slug: "osaka",
    },
    alt: "大阪府内でスポーツ観戦ができるスポーツバー",
  },
  {
    id: 6,
    name: "京都",
    masterPrefecture: {
      slug: "kyoto",
    },
    alt: "京都府内でスポーツ観戦ができるスポーツバー",
  },
];

// エリア一覧
export const PrefectureList: FC = () => {
  const [isPc, setIsPc] = useState(false);
  useEffect(() => {
    setIsPc(checkIsPcByScreenWidth());
  }, []);
  return (
    <ul className={styles.list}>
      {_.map(FEATURED_AREAS, (area) => {
        if (!area) {
          return null;
        }
        const imagePath = IFImg(
          "/img/index_area_i" + area.id + `${isPc ? "_pc" : ""}` + ".png"
        );
        return (
          <li key={area.id} className={styles.item}>
            <Link
              href={`/shops/${area.masterPrefecture?.slug}`}
              className={styles.link}
            >
              <img src={imagePath} alt={area.alt} />
              <p className={styles.name}>{area.name}</p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
