import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./index.module.css";

type Color = `#${string}` | string;
type Props = {
  highlightColor: Color;
  baseColor: Color;
};
type ColorPairKey = "RECOMMENDED_SHOPS" | "TEAM_RECOMMENDED_SHOPS";

export const COLOR_PAIR_MAP: Record<ColorPairKey, Props> = {
  RECOMMENDED_SHOPS: {
    highlightColor: "rgba(255,255,255,0.15)",
    baseColor: "#434343",
  },
  TEAM_RECOMMENDED_SHOPS: {
    highlightColor: "rgba(255,255,255,0.15)",
    baseColor: "#434343",
  },
};

const SkeletonShopListItem: React.FC<Props> = ({
  highlightColor,
  baseColor,
}) => {
  return (
    <div className={styles.link}>
      <dl className={styles.detail}>
        <dd className={styles.detailImage}>
          <div className={styles.detailImageWrap}>
            <Skeleton
              className={styles.image}
              borderRadius={0}
              highlightColor={highlightColor}
              baseColor={baseColor}
            />
          </div>
        </dd>
        <dt className={styles.detailTitle}>
          <Skeleton
            className={styles.text}
            height={16}
            borderRadius={0}
            highlightColor={highlightColor}
            baseColor={baseColor}
          />
        </dt>
        <dd className={styles.detailText}>
          <Skeleton
            className={styles.text}
            height={8}
            borderRadius={0}
            highlightColor={highlightColor}
            baseColor={baseColor}
          />
          <Skeleton
            className={styles.text}
            height={8}
            borderRadius={0}
            highlightColor={highlightColor}
            baseColor={baseColor}
          />
        </dd>
      </dl>
    </div>
  );
};

export default SkeletonShopListItem;
