import classNames from "classnames";
import _ from "lodash";
import Link from "next/link";
import * as React from "react";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";
import type { TeamList_UsersTeamFragment as UsersTeamFragment } from "./__generated__/fragments";
import type { TeamList_TeamsQuery as TeamsQuery } from "./__generated__/queries";

type TeamListProps = {
  teams: TeamsQuery["teamsUnbundled"];
};

// チーム一覧
export const TeamList: React.FC<TeamListProps> = ({ teams }) => {
  const href = (team: UsersTeamFragment) =>
    !team.masterSportGenre.slug || !team.slug
      ? paths.home
      : paths.team(team.masterSportGenre.slug, team.slug);

  return (
    <ul className={styles.list}>
      {_.map(teams, (team) => {
        if (!team) {
          return null;
        }
        return (
          <li key={team.id} className={styles.item}>
            <Link href={href(team)} className={styles.link}>
              <dl className={styles.detail}>
                <dd
                  className={classNames(
                    styles.teamImg,
                    team.thumbnailPath ? "" : "hidden"
                  )}
                >
                  <img src={IFImg(team.thumbnailPath)} alt="" />
                </dd>
                <dt className={styles.teamName}>{team.name}</dt>
              </dl>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
