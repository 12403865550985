import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import Slick from "react-slick";
import { ImageFlux } from "@components/ImageFlux";
import styles from "./index.module.css";
import type { Settings, CustomArrowProps } from "react-slick";

const PrevArrow: React.FC<CustomArrowProps> = ({ onClick, currentSlide }) => {
  const disabled = typeof currentSlide === "undefined" || currentSlide === 0;

  return (
    <div
      className={classNames(styles.arrow, styles.prev, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <img src="/img/slider_arrow.svg" alt="arrow-prev" />
    </div>
  );
};

const NextArrow: React.FC<CustomArrowProps> = ({
  onClick,
  currentSlide,
  slideCount,
}) => {
  const disabled =
    typeof currentSlide === "undefined" ||
    typeof slideCount === "undefined" ||
    currentSlide === slideCount - 1;

  return (
    <div
      className={classNames(styles.arrow, styles.next, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <img src="/img/slider_arrow.svg" alt="arrow-next" />
    </div>
  );
};

export type Photo = {
  id: string | number;
  alt: string;
  src: string;
  description?: string;
};

type Props = {
  imageClassName?: string;
  activeIndex: number;
  photos: Photo[];
  onChange?: (next: number, current: number) => void;
} & Settings;

export const Slider: React.FC<Props> = ({
  imageClassName,
  activeIndex,
  photos,
  onChange,
  dots = false,
  infinite = false,
  arrows = false,
  prevArrow,
  nextArrow,
  ...rest
}) => {
  const ref = useRef<Slick>(null);
  const [initialSlide] = useState<number>(activeIndex);

  useEffect(() => {
    ref.current?.slickGoTo(activeIndex);
  }, [activeIndex]);

  const SlideSetting: Settings = {
    dots,
    infinite,
    arrows,
    initialSlide,
    prevArrow: prevArrow ?? <PrevArrow />,
    nextArrow: nextArrow ?? <NextArrow />,
    beforeChange: (current, next) => onChange && onChange(next, current),
    ...rest,
  };

  return (
    <Slick {...SlideSetting} ref={ref}>
      {photos.map((photo) => (
        <div className={styles.wrapper} key={photo.id}>
          <ImageFlux
            className={imageClassName}
            imageType="fullScreen"
            url={photo.src}
            alt={photo.alt}
          />
        </div>
      ))}
    </Slick>
  );
};
