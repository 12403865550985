import * as React from "react";
import Skeleton from "react-loading-skeleton";
import { IFImg } from "@libs/utils/IFImg";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./index.module.css";

const SkeletonKeyVisual: React.FC = () => {
  return (
    <div className={styles.keyVisualImage}>
      <img
        src={IFImg("/img/cover_transparent.png")}
        alt=""
        width={630}
        height={345}
      />
      <Skeleton
        className={styles.image}
        borderRadius={0}
        highlightColor="rgba(255,255,255,0.15)"
        baseColor="#3D3D3D"
      />
    </div>
  );
};

export default SkeletonKeyVisual;
