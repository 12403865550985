import classNames from "classnames";
import _ from "lodash";
import Link from "next/link";
import { useEffect } from "react";
import * as React from "react";
import FavoriteTeam from "@components/FavoriteTeam";
import { HorizontalShopCardList } from "@components/HorizontalShopCardList";
import { FavoriteTeamMatchBox } from "@components/PersonalSections/FavoriteTeamMatchBox";
import { FavoriteTeamNewsBox } from "@components/PersonalSections/FavoriteTeamNewsBox";
import { FavoriteTeamShopBox } from "@components/PersonalSections/FavoriteTeamShopBox";
import Auth from "@hooks/useAuth";
import { paths } from "@libs/paths";
import { usePersonalSections_PersonalSectionsQuery as usePersonalSectionsQuery } from "./__generated__/queries";
import styles from "./index.module.css";

const PersonalSections: React.FC = () => {
  const { fanstaUser } = Auth.useContainer();
  const favoriteTeamIds =
    fanstaUser?.currentUser.user?.favoriteTeams.map((t) => t.id) ?? [];

  const { data, refetch } = usePersonalSectionsQuery({});

  const teamNewsArticles = data?.favoriteTeamLatestNewsArticle;
  const teamShops = data?.favoriteTeamShops ?? [];
  const teamMatches = _.flatten(
    data?.favoriteTeamMatches.map((match) => match.nodes ?? [])
  );
  const favoriteShops = data?.favoriteShops.nodes ?? [];

  useEffect(() => {
    refetch();
  }, [fanstaUser]);

  if (!data) {
    return null;
  }

  return (
    <>
      {favoriteShops.length > 0 && (
        <section className={classNames(styles.section)}>
          <>
            <div className={classNames("l-main__innerWidth", styles.container)}>
              <div className={styles.title}>
                <h2 className="c-contentTitle c-contentTitle--fz24">
                  お気に入りのお店
                </h2>
                <Link href={paths.mypageFavoriteShops} className={styles.link}>
                  すべて見る
                </Link>
              </div>
            </div>
            <div className={styles.favoriteList}>
              <HorizontalShopCardList shops={favoriteShops} />
            </div>
          </>
        </section>
      )}
      <section className={classNames(styles.section)}>
        <div className={classNames("l-main__innerWidth", styles.container)}>
          <FavoriteTeam pageType="top" />
          {teamNewsArticles && (
            <FavoriteTeamNewsBox
              newsArticle={teamNewsArticles}
              favoriteTeamIds={favoriteTeamIds}
            />
          )}
          {teamMatches.length > 0 && (
            <FavoriteTeamMatchBox
              matches={teamMatches}
              favoriteTeamIds={favoriteTeamIds}
            />
          )}
          {teamShops.length > 0 && (
            <FavoriteTeamShopBox
              shops={teamShops}
              favoriteTeamIds={favoriteTeamIds}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default PersonalSections;
