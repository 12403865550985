import classNames from "classnames";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  className?: string;
};

export const CoronaNote: FC<Props> = ({ className }) => {
  return (
    <p className={classNames(styles.note, className)}>
      新型コロナウイルスの影響により、営業時間が変更されている場合がございます。各店舗へご確認ください。
    </p>
  );
};
