import classNames from "classnames";
import { useState } from "react";
import * as React from "react";
import Slider from "react-slick";
import { ImageFlux } from "@components/ImageFlux";
import { OverlayPhoto } from "@components/OverlayPhoto";
import type { UsersShopImageType } from "@graphql/__generated__/types";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import styles from "./index.module.css";
import type { Settings, CustomArrowProps } from "react-slick";

export const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div
      className={`${styles.slideArrow} ${styles.slideArrowPrev}`}
      onClick={onClick}
    >
      <img src="/img/slider_arrow_02.svg" alt="" />
    </div>
  );
};

export const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div
      className={`${styles.slideArrow} ${styles.slideArrowNext}`}
      onClick={onClick}
    >
      <img src="/img/slider_arrow_02.svg" alt="" />
    </div>
  );
};

type Props = {
  shopImageList: UsersShopImageType[];
};

type OverlayPhotoOptions = {
  opened: boolean;
  startIndex: number;
};

const VisualSlider: React.FC<Props> = ({ shopImageList }) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  const imageList = shopImageList
    .slice()
    .sort((a, b) => a.masterShopImageId - b.masterShopImageId || a.id - b.id);
  const length = imageList.length;

  const SlideSetting: Settings = {
    dots: false,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (currentSlide: number) => {
      setCurrentIndex(currentSlide + 1);
    },
    responsive: [
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [overlayPhotoOptions, setOverlayPhotoOptions] =
    useState<OverlayPhotoOptions>({ startIndex: 0, opened: false });

  const handleImageClicked = (index: number) => {
    setOverlayPhotoOptions({
      opened: true,
      startIndex: index, // PC版で端の画像が選択されたときはその画像がオーバーレイに表示されるように、currentIndexでななくindexを使用している
    });
    GTMEvent(GTMEvents.shopCarouselPressed);
  };

  return (
    <>
      <div className={styles.slide}>
        <Slider {...SlideSetting}>
          {imageList.map((shopImage, index) => {
            return (
              <div
                className={classNames(
                  styles.slideItems,
                  styles.isOverlayPhotoOptions //スライドショーを起動するスライドに付与するClass名（hover処理を追加）
                )}
                key={shopImage.id}
                onClick={() => {
                  handleImageClicked(index);
                }}
              >
                <ImageFlux
                  imageType={"shopTop"}
                  url={shopImage.imageUrl}
                  alt={shopImage.imagePath}
                />
              </div>
            );
          })}
        </Slider>
        <div className={styles.slideCount}>{`${currentIndex}/${length}`}</div>
      </div>

      {overlayPhotoOptions.opened && (
        <OverlayPhoto
          defaultActiveIndex={overlayPhotoOptions.startIndex}
          open={overlayPhotoOptions.opened}
          photos={imageList.map((shopImage) => ({
            id: shopImage.id,
            alt: shopImage.imagePath,
            src: shopImage.imageUrl,
          }))}
          onClose={(): void =>
            setOverlayPhotoOptions({ opened: false, startIndex: 0 })
          }
        />
      )}
    </>
  );
};

export default VisualSlider;
