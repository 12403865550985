import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import { IFImg } from "@libs/utils/IFImg";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import styles from "./index.module.css";

type Props = {
  href?: string;
  imgPath: string;
  alt: string;
  isTargetBlank?: boolean;
};

const CoverSlide: React.FC<Props> = ({
  href,
  imgPath,
  alt,
  isTargetBlank = false,
}) => {
  const onClick = (): void => {
    if (!href) {
      // 実際にhrefなしで使用されるケースないと思われる（実際に現時点2022.09.16ではない）が念の為
      return;
    }
    GTMEvent(GTMEvents.carouselPressed(href));
  };

  return (
    <>
      {href ? (
        <Link href={href} target={isTargetBlank ? "_blank" : undefined}>
          <img
            onClick={onClick}
            className={styles.indexKeyVisualImage}
            src={IFImg(imgPath)}
            alt={alt}
            width="630"
            height="345"
          />
        </Link>
      ) : (
        <img
          className={classNames(styles.indexKeyVisualImage, styles.disabled)}
          src={IFImg(imgPath)}
          alt={alt}
          width="750"
          height="432"
        />
      )}
    </>
  );
};

export default CoverSlide;
