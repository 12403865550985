import classNames from "classnames";
import _ from "lodash";
import Link from "next/link";
import { useState } from "react";
import { Banner } from "@components/Banner";
import { Button } from "@components/Button";
import { TopHead } from "@components/CommonHead";
import CommonLayout from "@components/CommonLayout";
import { CoronaNote } from "@components/CoronaNote";
import CoverSlider from "@components/CoverSlider";
import { HorizontalShopCardList } from "@components/HorizontalShopCardList";
import { NewsCard } from "@components/NewsCard";
import PersonalSections from "@components/PersonalSections";
import PopularAreaList from "@components/PopularAreaList";
import { PrefectureList } from "@components/PrefectureList";
import ReservationReminderPanel from "@components/ReservationReminderPanel";
import { SearchInput } from "@components/SearchInput";
import SkeletonKeyVisual from "@components/Skeleton/SkeletonKeyVisual";
import SkeletonRecommendedShops from "@components/Skeleton/SkeletonRecommendedShops";
import SkeletonTopNews from "@components/Skeleton/SkeletonTopNews";
import SkeletonTopSearchInput from "@components/Skeleton/SkeletonTopSearchInput";
import { TeamSection } from "@components/TeamSection";
import Toaster from "@components/Toaster";
import { TournamentMatchBox } from "@components/TournamentMatchBox";
import Auth from "@hooks/useAuth";
import { PredefinedMessages } from "@hooks/useMessage";
import type { SearchCondition } from "@hooks/useSearchCondition";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import { useIndexPageQuery, useTopPageQuery } from "./__generated__/queries";
import styles from "./index.module.css";
import type { NextPage } from "next";

// バナー無しの場合
const bannerLink = null;

// バナーを表示する場合は、ここを設定する。
// const bannerLink = {
//   href: "#",
//   imagePath: "/img/index_ad.png",
//   alt:
//     "期間限定 プレミアユニフォーム当たる 応募期間 2021/03/01（正午）〜03/31（正午）"
// };

const now = new Date();

const Index: NextPage = () => {
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    {} as SearchCondition
  );

  const teamsByTournamentState = TeamsByTournamentState.useContainer();

  const { isFanstaUserRegisteredAndValidated, fanstaUser } =
    Auth.useContainer();

  // UI上のリーグの選択状態
  const {
    initializeTournamentId,
    setTeamListTournamentId,
    allTeamsLoading,
    allTournaments,
  } = teamsByTournamentState;

  const {
    data: topPageData,
    loading: topPageLoading,
    error: topPageError,
  } = useTopPageQuery({
    context: { skipAuth: true },
  });

  const matchSectionData = topPageData?.fanstaConfig.topPage.matchSection;
  const firstTournamentSetting = matchSectionData?.tournaments[0];
  const secondTournamentSetting = matchSectionData?.tournaments[1];
  const thirdTournamentSetting = matchSectionData?.tournaments[2];

  // depends on tournaments query
  const { data, loading, error } = useIndexPageQuery({
    variables: {
      now: now.toISOString(),
      // 試合の表示件数の最大
      limitOfMatches: matchSectionData?.limitOfMatches,

      firstTournamentIds: firstTournamentSetting?.tournamentId,
      firstTournamentIdsTba: firstTournamentSetting?.shouldIncludeTba
        ? firstTournamentSetting?.tournamentId
        : [],
      firstTournamentTeamIds: firstTournamentSetting?.filteringTeamIds,
      shouldFilterFirstTournamentByTwoWeeks:
        firstTournamentSetting?.shouldFilterByTwoWeeks,

      includeSecondTournamentMatches: !!secondTournamentSetting,
      secondTournamentIds: secondTournamentSetting?.tournamentId,
      secondTournamentIdsTba: secondTournamentSetting?.shouldIncludeTba
        ? secondTournamentSetting?.tournamentId
        : [],
      secondTournamentTeamIds: secondTournamentSetting?.filteringTeamIds,
      shouldFilterSecondTournamentByTwoWeeks:
        secondTournamentSetting?.shouldFilterByTwoWeeks,

      includeThirdTournamentMatches: !!thirdTournamentSetting,
      thirdTournamentIds: thirdTournamentSetting?.tournamentId,
      thirdTournamentIdsTba: thirdTournamentSetting?.shouldIncludeTba
        ? thirdTournamentSetting.tournamentId
        : [],
      thirdTournamentTeamIds: thirdTournamentSetting?.filteringTeamIds,
      shouldFilterThirdTournamentByTwoWeeks:
        thirdTournamentSetting?.shouldFilterByTwoWeeks,
    },
    onCompleted: (): void => {
      initializeTournamentId();
      // チーム一覧の選択状態は固定値の最初のIDとする。
      setTeamListTournamentId(firstTournamentSetting?.tournamentId ?? null);
    },
    skip: allTeamsLoading || topPageLoading || !!topPageError,
    context: {
      // Authが有効だとクエリが遅くなるので、公開APIの場合は明示的にskipする。
      skipAuth: true,
    },
  });

  if (error) {
    console.error(error);
  }
  if (topPageError) {
    console.error(topPageError);
  }

  const matchSectionTournaments = [
    firstTournamentSetting,
    secondTournamentSetting,
    thirdTournamentSetting,
  ].map(
    (setting) => allTournaments?.find((t) => t.id === setting?.tournamentId)
  );

  const matchesByTournaments = [
    data?.firstTournamentMatches,
    data?.secondTournamentMatches,
    data?.thirdTournamentMatches,
  ].map((tournamentMatches) =>
    _.flatten((tournamentMatches || []).map((match) => match.nodes || []))
  );

  const hasAnyMatches = matchesByTournaments.some(
    (tournamentMatches) => tournamentMatches.length > 0
  );
  const shopRecommends = data?.shopRecommends || [];

  const newsArticles = data?.newsArticles.nodes;
  const showSkeleton = Boolean(
    loading || allTeamsLoading || error || topPageLoading || topPageError
  );

  return (
    <CommonLayout hasFooter noHeaderButton={showSkeleton} hasHeaderPoints>
      {TopHead}

      <div className={styles.main}>
        <Toaster
          messageFilter={[
            PredefinedMessages.emailRecovered,
            PredefinedMessages.verificationUrlInvalid,
          ]}
        />

        <section className={styles.keyVisual}>
          {showSkeleton ? <SkeletonKeyVisual /> : <CoverSlider />}

          {showSkeleton ? (
            <SkeletonTopSearchInput />
          ) : (
            <SearchInput
              className={"keyVisualSearch"}
              teamSearchPlaceholder="チーム"
              onChange={(searchCondition): void => {
                setSearchCondition(searchCondition);
              }}
              submitText="設定する"
              teamSearchPosition="right"
              isCompactPc
            >
              <Link href={paths.shops(searchCondition)} passHref legacyBehavior>
                <Button
                  className={classNames(styles.btn, styles.searchBtn)}
                  styleType="filled"
                  colorType="primary"
                >
                  お店を探す
                </Button>
              </Link>
            </SearchInput>
          )}
        </section>

        <section className={styles.reservation}>
          <div className={classNames("l-main__innerWidth", styles.container)}>
            <ReservationReminderPanel />
          </div>
        </section>

        {bannerLink && (
          <div className={styles.banner}>
            <div className={classNames("l-main__innerWidth", styles.container)}>
              <Banner link={bannerLink} />
            </div>
          </div>
        )}

        {isFanstaUserRegisteredAndValidated && <PersonalSections />}

        <section className={styles.news}>
          <div className={classNames("l-main__innerWidth", styles.container)}>
            {showSkeleton ? (
              <SkeletonTopNews />
            ) : (
              newsArticles &&
              newsArticles.length !== 0 && (
                <>
                  <div className={classNames(styles.newsTitle, styles.heading)}>
                    <h2 className="c-contentTitle c-contentTitle--fz24">
                      ニュース
                    </h2>
                    <Link
                      href={paths.newsArticles}
                      className={styles.newsLink}
                      onClick={() => {
                        GTMEvent(
                          GTMEvents.toNewsListPagePressed(
                            "news_all_on_top_page"
                          )
                        );
                      }}
                    >
                      全ニュースを見る
                    </Link>
                  </div>
                  <div className={styles.newsArticlesList}>
                    {newsArticles.map((n) => (
                      <NewsCard
                        newsArticle={n}
                        key={n.id}
                        onClick={() => {
                          GTMEvent(
                            GTMEvents.toNewsDetailPagePressed(
                              "news_all_on_top_page",
                              n.id,
                              fanstaUser?.currentUser.user?.favoriteTeams.map(
                                (ft) => ft.id
                              )
                            )
                          );
                        }}
                      />
                    ))}
                  </div>
                </>
              )
            )}
          </div>
        </section>

        <section className={classNames(styles.section, styles.recommend)}>
          {showSkeleton ? (
            <SkeletonRecommendedShops />
          ) : (
            <>
              <div className={styles.heading}>
                <div
                  className={classNames("l-main__innerWidth", styles.container)}
                >
                  <h2 className="c-contentTitle c-contentTitle--fz24">
                    おすすめのお店
                  </h2>
                </div>
              </div>
              <div className={styles.recommendList}>
                <HorizontalShopCardList
                  shops={shopRecommends}
                  onShopClick={(index) =>
                    GTMEvent(GTMEvents.recommendedShopPressed(index + 1))
                  }
                />
              </div>
            </>
          )}
        </section>

        <div className={classNames("l-main__innerWidth", styles.container)}>
          <CoronaNote className={styles.note} />
        </div>

        {hasAnyMatches && (
          <section className={classNames(styles.section, styles.match)}>
            <div className={classNames("l-main__innerWidth", styles.container)}>
              <div className={classNames(styles.matchTitle, styles.heading)}>
                <h2 className="c-contentTitle c-contentTitle--fz24 md:none">
                  試合から探す
                </h2>
                <h2 className="c-contentTitle c-contentTitle--fz24 none md:block">
                  試合からスポーツバーを探す
                </h2>
                <Link href={paths.matches({})} className={styles.matchLink}>
                  全日程を見る
                </Link>
              </div>

              {_.zip(matchSectionTournaments, matchesByTournaments).map(
                ([tournament, tournamentMatches]) =>
                  tournament &&
                  tournamentMatches && (
                    <TournamentMatchBox
                      key={tournament.id}
                      matches={tournamentMatches}
                      tournament={tournament}
                    />
                  )
              )}
            </div>
          </section>
        )}

        <section className={classNames(styles.section, styles.area)}>
          <div className={classNames("l-main__innerWidth", styles.container)}>
            <div className={classNames(styles.heading, styles.areaTitle)}>
              <h2 className="c-contentTitle c-contentTitle--fz24 md:none">
                エリアから探す
              </h2>
              <h2 className="c-contentTitle c-contentTitle--fz24 none md:block">
                エリアからスポーツバーを探す
              </h2>
              <Link
                href="https://support.fansta.jp/hc/ja/articles/7969663457561"
                className={styles.areaLink}
              >
                全エリアを見る
              </Link>
            </div>
            <PrefectureList />
            <PopularAreaList />
          </div>
        </section>

        <TeamSection />

        <div className={styles.column}>
          <div className={classNames(styles.columnItem, styles.daznBanner)}>
            <div className="l-main__innerWidth">
              <a href="https://www.dazn.com/" target="_blank">
                <img
                  src={IFImg("/img/index_banner_dazn_202401.png")}
                  alt="DAZN 自宅でもスポーツ観戦"
                />
              </a>
            </div>
          </div>

          <section className={classNames(styles.gnavi, styles.columnItem)}>
            <div className="l-main__innerWidth">
              <a
                href="https://pr.gnavi.co.jp/promo/sports-kansen/?sc_cid=pr_fansta"
                target="_blank"
              >
                <img
                  src={IFImg("/img/index_gnavi.jpg")}
                  alt="DAZNでスポーツ上映中の飲食店をぐるなびでもチェック！ 仲間と一緒に、スポーツファンと一緒に 見たい試合、好きなチームをお店で応援しよう！"
                />
              </a>
            </div>
          </section>

          <section className={classNames(styles.shop, styles.columnItem)}>
            <div className="l-main__innerWidth">
              <div className={styles.shopContentWrapper}>
                <h2 className={styles.title}>飲食店の方へ</h2>
                <p className={styles.text}>
                  掲載・資料請求をご希望の店舗様は
                  <br />
                  以下からお問い合わせください。
                </p>
                <Button
                  className={styles.btn}
                  styleType="outlined"
                  colorType="primary"
                  href={paths.aboutShop}
                >
                  飲食店の方はこちら
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Index;
