import * as React from "react";
import { MatchListItem } from "@components/MatchList/MatchListItem";
import type { MatchList_MatchListItemFragment } from "@components/MatchList/__generated__/fragments";
import styles from "./index.module.css";

type TopMatchListProps = {
  matches: Array<MatchList_MatchListItemFragment>;
  forceDynamicUrl?: boolean;
};

export const TopMatchList: React.FC<TopMatchListProps> = ({
  matches,
  forceDynamicUrl,
}) => {
  return (
    <div className={styles.wrapper}>
      {matches?.map((match) => (
        <MatchListItem
          key={match.id}
          match={match}
          className={styles.item}
          forceDynamicUrl={forceDynamicUrl}
        />
      ))}
    </div>
  );
};
