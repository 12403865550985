import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./index.module.css";

const SkeletonTopSearchInput: React.FC = () => {
  return (
    <div className={styles.keyVisualSearch}>
      <ul className={styles.list}>
        <li>
          <Skeleton
            height={18}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.15)"
            baseColor="#3D3D3D"
          />
        </li>
        <li>
          <Skeleton
            height={18}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.15)"
            baseColor="#3D3D3D"
          />
        </li>
      </ul>
      <div className={styles.btn}>
        <Skeleton
          className="hoge"
          height={18}
          borderRadius={0}
          highlightColor="#E6FF3B"
          baseColor="#C4D932"
        />
      </div>
    </div>
  );
};

export default SkeletonTopSearchInput;
