import classNames from "classnames";
import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonShopListItem, {
  COLOR_PAIR_MAP,
} from "@components/Skeleton/SkeletonShopListItem";
import styles from "./index.module.css";

const SHOP_ITEM_LENGTH = 3;
const SkeletonRecommendedShops: React.FC = () => {
  return (
    <>
      <div className={classNames("l-main__innerWidth", styles.container)}>
        <h2 className="c-contentTitle c-contentTitle--fz24">
          <Skeleton
            className={styles.title}
            height={32}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.15)"
            baseColor="#434343"
          />
        </h2>
      </div>
      <div className={styles.recommendList}>
        <ul className={styles.list}>
          {[...Array(SHOP_ITEM_LENGTH).keys()].map((n) => (
            <li key={n}>
              <SkeletonShopListItem {...COLOR_PAIR_MAP.RECOMMENDED_SHOPS} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SkeletonRecommendedShops;
