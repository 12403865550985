import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonNewsCard from "@components/Skeleton/SkeletonTopNews/SkeletonNewsCard";
import styles from "./index.module.css";

const NEWS_ITEM_LENGTH = 3;
const SkeletonTopNews: React.FC = () => {
  return (
    <>
      <h2 className="c-contentTitle c-contentTitle--fz24">
        <Skeleton
          className={styles.title}
          height={32}
          borderRadius={0}
          highlightColor="rgba(255,255,255,0.15)"
          baseColor="#3D3D3D"
        />
      </h2>
      <div className={styles.newsArticlesList}>
        {[...Array(NEWS_ITEM_LENGTH).keys()].map((n) => (
          <SkeletonNewsCard key={n} />
        ))}
      </div>
    </>
  );
};

export default SkeletonTopNews;
