import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import Slider from "react-slick";
import { NextArrow } from "@components/HorizontalShopCardList/NextArrow";
import { PrevArrow } from "@components/HorizontalShopCardList/PrevArrow";
import { ShopCardSmall } from "@components/ShopCardListItem/ShopCardSmall";
import type { ShopCardSmall_ShopListItemFragment as ShopListItemFragment } from "@components/ShopCardListItem/ShopCardSmall/__generated__/fragments";
import type { ShopCardListItem_MatchListItemFragment as MatchListItemFragment } from "@components/ShopCardListItem/__generated__/fragments";
import styles from "./index.module.css";
import type { Settings } from "react-slick";

type Props = {
  shops: Array<ShopListItemFragment>;
  onShopClick?: (index: number) => void;
  onMoreButtonClick?: () => void;
  hasMoreButton?: boolean;
  moreButtonLinkPath?: string;
  customDisplayMatch?: MatchListItemFragment;
  moreText?: string;
};

export const HorizontalShopCardList: React.FC<Props> = ({
  shops,
  onShopClick,
  onMoreButtonClick,
  hasMoreButton,
  moreButtonLinkPath = "",
  customDisplayMatch,
  moreText = "その他",
}) => {
  const SlideSetting: Settings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    swipe: false,
    swipeToSlide: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <>
      <div className="md:none">
        <div className={styles.shopCardList}>
          {shops.map((shop, index) => (
            <ShopCardSmall
              key={shop.uuid}
              shop={shop}
              onClick={() => onShopClick?.(index)}
              customDisplayMatch={customDisplayMatch}
            />
          ))}

          {hasMoreButton && (
            <div className={styles.moreCard}>
              <Link
                href={moreButtonLinkPath}
                className={styles.moreButton}
                onClick={() => onMoreButtonClick?.()}
              >
                <img
                  className={styles.moreButtonIcon}
                  src="/img/icon_search.svg"
                  alt=""
                />
                <span>{moreText}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={classNames("none md:block", styles.shopCardSlider)}>
        <Slider {...SlideSetting}>
          {shops.map((shop, index) => (
            <ShopCardSmall
              key={shop.uuid}
              shop={shop}
              onClick={() => onShopClick?.(index)}
              customDisplayMatch={customDisplayMatch}
              slider
            />
          ))}

          {hasMoreButton && (
            <div className={styles.moreCard}>
              <Link
                href={moreButtonLinkPath}
                className={styles.moreButton}
                onClick={() => onMoreButtonClick?.()}
              >
                <img
                  className={styles.moreButtonIcon}
                  src="/img/icon_search.svg"
                  alt=""
                />
                <span>{moreText}</span>
              </Link>
            </div>
          )}
        </Slider>
      </div>
    </>
  );
};
