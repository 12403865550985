import Link from "next/link";
import * as React from "react";
import ReservationStatus from "@components/ReservationStatus";
import { Text } from "@components/Text";
import Auth from "@hooks/useAuth";
import { paths } from "@libs/paths";
import { toJpReservationDatetime } from "@libs/utils/date";
import { useReservationReminderPanel_ReservationsQuery as useReservationsQuery } from "./__generated__/queries";
import styles from "./index.module.css";

const DEFAULT_PAGE = 1;
const PAGE_LIMIT = 3;

const ReservationReminderPanel: React.FC = () => {
  const { isFanstaUserRegisteredAndValidated } = Auth.useContainer();
  const { data } = useReservationsQuery({
    variables: {
      page: DEFAULT_PAGE,
      perPage: PAGE_LIMIT,
      asc: true,
      isActive: true,
    },
    skip: !isFanstaUserRegisteredAndValidated,
  });

  if (!data) {
    return null;
  }

  const totalCount = data.reservations.totalCount;
  const nodes = data.reservations.nodes;
  const {
    id: campaignId,
    imageUrl,
    linkUrl,
    text,
  } = data.fanstaConfig.highlightLotteryCampaign ?? {};
  const showHighlight = nodes.some((n) =>
    n.enterableLotteryCampaignIds.includes(campaignId)
  );

  if (nodes.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Text bold>現在の予約状況 ({totalCount}件)</Text>
        <div className={styles.link}>
          <Link href={paths.mypageReservations}>予約一覧</Link>
        </div>
      </div>
      {showHighlight && (
        <Link href={linkUrl} className={styles.highlightLink}>
          <div className={styles.highlight}>
            <div className={styles.highlightImage}>
              <img src={imageUrl} alt="" width={48} height={48} />
            </div>
            <div className={styles.highlightText}>{text}</div>
          </div>
        </Link>
      )}
      {nodes.map((n) => (
        <div className={styles.item} key={n.uuid}>
          <Link
            href={paths.mypageReservationDetail(n.uuid)}
            className={styles.inner}
          >
            <div>
              <div className={styles.date}>
                <Text size={12}>
                  {toJpReservationDatetime(n.comesAt, "dateEnNarrow")}{" "}
                  {n.match && <span>試合観戦</span>}
                </Text>
              </div>
              <div className={styles.name}>
                <Text size={14} bold>
                  {n.shop.name}
                </Text>
              </div>
            </div>
            <ReservationStatus
              state={n.state}
              cancelType={n.cancelTypeV2 || undefined}
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ReservationReminderPanel;
