import * as React from "react";
import { HorizontalShopCardList } from "@components/HorizontalShopCardList";
import type { ShopCardSmall_ShopListItemFragment as ShopListItemFragment } from "@components/ShopCardListItem/ShopCardSmall/__generated__/fragments";
import { TopListBox } from "@components/TopListBox";
import { paths } from "@libs/paths";
import styles from "./index.module.css";

type Props = {
  shops: Array<ShopListItemFragment>;
  favoriteTeamIds: Array<number>;
};
export const FavoriteTeamShopBox: React.FC<Props> = ({
  shops,
  favoriteTeamIds,
}) => {
  const link = paths.shops({
    teamIds: favoriteTeamIds,
  });

  return (
    <div className={styles.container}>
      <TopListBox
        title={"試合を上映するお店"}
        link={link}
        linkText={"お気に入りチームの試合上映店を探す"}
      >
        <div className={styles.favoriteTeamShop}>
          <HorizontalShopCardList shops={shops} />
        </div>
      </TopListBox>
    </div>
  );
};
