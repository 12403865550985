import * as React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./index.module.css";

const SkeletonNewsCard: React.FC = () => {
  return (
    <div className={styles.articleCard}>
      <div className={styles.articleThumbnail}>
        <Skeleton
          width={120}
          height={120}
          borderRadius={0}
          highlightColor="rgba(255,255,255,0.1)"
          baseColor="#5A5A5A"
        />
      </div>
      <div className={styles.articleInfo}>
        <div className={styles.articleTitle}>
          <Skeleton
            height={16}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.1)"
            baseColor="#5A5A5A"
          />
          <Skeleton
            className={styles.text}
            height={16}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.1)"
            baseColor="#5A5A5A"
          />
        </div>
        <div className={styles.articleCategory}>
          <Skeleton
            className={styles.text}
            height={16}
            borderRadius={0}
            highlightColor="rgba(255,255,255,0.1)"
            baseColor="#5A5A5A"
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonNewsCard;
