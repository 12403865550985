import * as React from "react";
import type { MatchList_MatchListItemFragment } from "@components/MatchList/__generated__/fragments";
import { TopListBox } from "@components/TopListBox";
import { TopMatchList } from "@components/TopMatchList";
import { paths } from "@libs/paths";
import styles from "./index.module.css";

type Props = {
  matches: Array<MatchList_MatchListItemFragment>;
  favoriteTeamIds: Array<number>;
};
export const FavoriteTeamMatchBox: React.FC<Props> = ({
  matches,
  favoriteTeamIds,
}) => {
  const link = paths.matches({
    teamIds: favoriteTeamIds,
  });

  return (
    <div className={styles.container}>
      <TopListBox
        title="今後の試合"
        link={link}
        linkText={"お気に入りチームの日程を見る"}
      >
        <TopMatchList matches={matches} />
      </TopListBox>
    </div>
  );
};
