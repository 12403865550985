import * as React from "react";

type BannerLink = {
  imagePath: string;
  alt: string;
  href: string;
} | null;

export const Banner: React.FC<{ link?: BannerLink }> = ({ link }) => {
  if (!link) {
    return null;
  }
  return (
    <a href={link.href}>
      <img src={link?.imagePath} alt={link?.alt} />
    </a>
  );
};

export default Banner;
