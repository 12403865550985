import classNames from "classnames";
import * as React from "react";
import styles from "../index.module.css";
import type { CustomArrowProps } from "react-slick";

type Props = {
  path?: string;
};

export const PrevArrow: React.FC<CustomArrowProps & Props> = ({
  onClick,
  currentSlide,
}) => {
  const disabled = typeof currentSlide === "undefined" || currentSlide === 0;
  return (
    <div
      className={classNames(styles.slideArrow, styles.slideArrowPrev, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <img src="/img/slider_arrow_bold.svg" alt="" />
    </div>
  );
};
