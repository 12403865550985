import classNames from "classnames";
import * as React from "react";
import styles from "../index.module.css";
import type { CustomArrowProps } from "react-slick";

type Props = {
  path?: string;
};

export const NextArrow: React.FC<CustomArrowProps & Props> = ({
  onClick,
  currentSlide,
  slideCount,
}) => {
  const disabled =
    typeof currentSlide === "undefined" ||
    typeof slideCount === "undefined" ||
    currentSlide === slideCount - 3;

  return (
    <div
      className={classNames(styles.slideArrow, styles.slideArrowNext, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <img src="/img/slider_arrow_bold.svg" alt="" />
    </div>
  );
};
