import classNames from "classnames";
import { useEffect, useState } from "react";
import Portal from "@components/Portal";
import type { Photo } from "@components/Slider";
import { Slider } from "@components/Slider";
import ToggleScroll from "@hooks/useToggleScroll";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  open: boolean;
  photos: Photo[];
  onClose: () => void;
  defaultActiveIndex?: number;
};

const OverlayPhotoContent: FC<Props> = ({
  photos,
  onClose,
  defaultActiveIndex,
}) => {
  const [activePhotoIndex, setActivePhotoIndex] = useState(
    defaultActiveIndex ?? 0
  );

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [positionY, setPositionY] = useState(0);

  const toggleScroll = ToggleScroll.useContainer();

  useEffect(() => {
    setPositionY(window.pageYOffset);
    toggleScroll.disable();
    return (): void => toggleScroll.enable();
  }, []);

  if (photos.length === 0) {
    return null;
  }
  const { description } = photos[activePhotoIndex];

  const arrowPrevDisabled = activePhotoIndex <= 0;
  const arrowNextDisabled = activePhotoIndex >= photos.length - 1;

  return (
    <div className={styles.root}>
      <div className={styles.overlay}>
        <div
          className={styles.closeOverlay}
          onClick={(): void => {
            setTimeout(() => {
              window.scroll({ top: positionY });
              onClose();
            }, 100);
          }}
        />
        <button
          type="button"
          className={styles.close}
          onClick={(): void => {
            toggleScroll.enable();
            setTimeout(() => {
              window.scroll({ top: positionY });
              onClose();
            }, 100);
          }}
        />
        <div className={styles.container}>
          <Slider
            className={styles.slider}
            imageClassName={styles.image}
            photos={photos}
            activeIndex={activePhotoIndex}
            afterChange={(): void => setIsImageLoading(false)}
            onChange={(next): void => setActivePhotoIndex(next)}
          />
          <div className={styles.nav}>
            {description && <p className={styles.text}>{description}</p>}
            <div className={styles.arrows}>
              <button
                type="button"
                disabled={isImageLoading}
                className={classNames(styles.arrow, styles["arrow-prev"], {
                  [styles["arrow-prev-disabled"]]: arrowPrevDisabled,
                })}
                onClick={(): void => {
                  if (!arrowPrevDisabled) {
                    setIsImageLoading(true);
                    setActivePhotoIndex((prev) => prev - 1);
                  }
                }}
              />
              <p className={styles.pager}>
                {activePhotoIndex + 1}/{photos.length}
              </p>
              <button
                type="button"
                disabled={isImageLoading}
                className={classNames(styles.arrow, styles["arrow-next"], {
                  [styles["arrow-next-disabled"]]: arrowNextDisabled,
                })}
                onClick={(): void => {
                  if (!arrowNextDisabled) {
                    setIsImageLoading(true);
                    setActivePhotoIndex((prev) => prev + 1);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OverlayPhoto: FC<Props> = (props) => {
  return (
    <Portal targetId="overlay-photo">
      {props.open && <OverlayPhotoContent {...props} />}
    </Portal>
  );
};
